<template>
  <div class="pt-12" v-if="gallerySortedByPosition">
    <div class="v-card-text text-h5 font-weight-bold text-center">
      {{props.title}}
    </div>
    <v-divider></v-divider>
    <v-data-iterator v-if="gallerySortedByPosition" class="mt-6" :items-per-page="itemsPerPage" :page="page"
      :items="gallerySortedByPosition">


      <template v-slot:no-data>
        <v-progress-circular indeterminate color="primary" :size="85" :width="6"></v-progress-circular>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col v-for="(galleryItem, index) in props.items" :key="index" sm="4" cols="12" :md="index % 3 == 0 ? '2' : '3'"
            :lg="index % 2 == 0 ? '4' : '2'">

            <NuxtImg height="200px" style="    width: 100%;
    object-fit: cover; border-radius: 10px;" width="100%"
    image-style="small"
              @click="() => openModal(galleryItem.selectable as unknown as GalleryItem)" fit="cover"
              :placeholder="img((galleryItem.selectable as unknown as GalleryItem).url.loading, { h: 10, f: 'png', blur: 2, q: 50 })"
              loading="lazy" :lazy-src="(galleryItem.selectable as unknown as GalleryItem).url.loading"
              :src="(galleryItem.selectable as unknown as GalleryItem).url.original" />

          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <div class="d-flex align-center justify-space-around pa-4">
          <span class="text-caption">Imágenes por página</span>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn variant="text" color="primary" class="ml-2" append-icon="mdi-chevron-down" v-bind="props">
                {{ itemsPerPage }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" :title="number"
                @click="itemsPerPage = number"></v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 text-caption grey--text">
            Página {{ page }} de {{ numberOfPages }}
          </span>
          <v-btn elevation="0" icon size="small" @click="prevPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn elevation="0" icon size="small" class="ml-2" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-iterator>
    <v-dialog v-if="dialog" max-width="600" v-model="dialog">
      <GaleriaModal @close-dialog="closeModal" :imagen-seleccionada="(imagenSeleccionada as GalleryItem)" />
    </v-dialog>
  </div>
  <v-progress-circular indeterminate v-else color="primary" :size="85" :width="6"></v-progress-circular>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { GalleryItem } from "@/types";
import { bannersSort } from "@/helpers";

interface Props {
  gallery: GalleryItem[],
  title: String,
}
const img = useImage()
const props = defineProps<Props>();

const gallerySortedByPosition = computed(() => {
  return props.gallery.sort(function (a, b) {
    return a.position - b.position;
  });
});

const itemsPerPageArray = ref<number[]>([4, 8, 12, 24, 48]);
const itemsPerPage = ref<number>(8);
const page = ref<number>(1);
const search = ref<string>("");

const numberOfPages = computed(() =>
  props.gallery.length === 0 || itemsPerPage.value === 0
    ? 0
    : Math.ceil(props.gallery.length / itemsPerPage.value)
);

const nextPage = () => {
  if (page.value + 1 <= numberOfPages.value) page.value += 1;
};

const prevPage = () => {
  if (page.value - 1 >= 1) page.value -= 1;
};

const dialog = ref<boolean>(false);
const imagenSeleccionada = ref<GalleryItem | null>(null);

const openModal = (galleryItem: GalleryItem) => {
  imagenSeleccionada.value = galleryItem;
  dialog.value = true;
};

const closeModal = () => {
  dialog.value = false;
  imagenSeleccionada.value = null;
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/responsive";

// .galery {
//   height: 100%;
//   img {
//     height: 200px;
//     width: 100%;
//     object-fit: cover;
//     border-radius: 10px;
//   }
// }
</style>
